import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    passRequested: false,
    email: null,
    form: null,
    formId: null,
    included: [],
    activeSectionKey: null,
    activeBlockKey: null,
    activeBlockType: null,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getFormId(state) {
      return state.formId;
    },
    getForm(state) {
      return state.form;
    },
    getFormAttr(state) {
      return state.form.attributes;
    },
    getSections(state) {
      let sections = state.form.attributes.sections;
      if (sections) {
        return sections;
      }
      return [];
    },
    getActiveSection(state) {
      let sections = state.form.attributes.sections;
      let section = sections.find(
        (item) => item.key === state.activeSectionKey
      );
      return section;
    },
    getActiveSectionKey(state) {
      return state.activeSectionKey;
    },
    getActiveBlockKey(state) {
      return state.activeBlockKey;
    },
    getActiveBlockType(state) {
      return state.activeBlockType;
    },
    getStyling(state) {
      return state.form.attributes.styling;
    },
    getFormData(state) {
      if (state.form) {
        return state.form.attributes.data;
      } else {
        return null;
      }
    },
    getIncluded(state) {
      return state.included;
    },
    getPassRequested(state) {
      return state.passRequested;
    },
    getEmail(state) {
      return state.email;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setPassRequested(state, value) {
      state.passRequested = value;
    },
    setFormId(state, formId) {
      state.formId = formId;
    },
    setForm(state, form) {
      state.form = form;
    },
    setAssetForms(state, assetforms) {
      if (state.form.attributes) {
        state.form.attributes.assetforms = assetforms;
      }
    },
    setSections(state, value) {
      state.form.attributes.sections = value;
    },
    setStyling(state, value) {
      state.form.attributes.styling = value;
    },
    setActiveSectionKey(state, value) {
      state.activeSectionKey = value;
    },
    setActiveBlockKey(state, value) {
      state.activeBlockKey = value;
    },
    setActiveBlockType(state, value) {
      state.activeBlockType = value;
    },
    addSection(state, value) {
      if (!state.form.attributes.sections) {
        state.form.attributes.sections = [];
      }
      state.form.attributes.sections.push(value);
    },
    deleteSection(state, section) {
      const keyToDelete = section.key;
      const index = state.form.attributes.sections.findIndex(
        (item) => item.key === keyToDelete
      );
      if (index !== -1) {
        state.form.attributes.sections.splice(index, 1);
      }
    },
    setIncluded(state, value) {
      state.included = value;
    },
  },
  actions: {
    fetchForm(state) {
      state.commit("setLoading", true);
      let apiurl = process.env.VUE_APP_APIURL;
      axios
        .get(apiurl + "forms/" + state.getters.getFormId, {
          params: {},
        })
        .then((response) => {
          // console.log(response.data);
          state.commit("setForm", response.data.data);
          state.commit("setIncluded", response.data.included);
          const cssContent = response.data.data.attributes.css;
          // Create a new style element
          const styleElement = document.createElement("style");
          styleElement.setAttribute("type", "text/css");

          if (styleElement.styleSheet) {
            // For IE8 and below
            styleElement.styleSheet.cssText = cssContent;
          } else {
            // For modern browsers
            styleElement.appendChild(document.createTextNode(cssContent));
          }

          // Append the style element to the document's head
          document.head.appendChild(styleElement);
          document.title = response.data.data.attributes.name;

          // set the first form section as active
          let formSections = response.data.data.attributes.sections.filter(
            (item) => item.type === "form" || item.type === undefined
          );
          let firstSection = formSections[0];
          state.commit("setActiveSectionKey", firstSection.key);

          state.commit("setLoading", false);
        })
        .catch((err) => {
          console.log(err);
          state.commit("setForm", null);
          state.commit("setIncluded", []);
          // window.location.href = "https://publey.com/";
        });
    },
    updateImages(state) {
      state.commit("setLoading", true);
      let apiurl = process.env.VUE_APP_APIURL;
      axios
        .get(apiurl + "forms/" + state.getters.getFormId, {
          params: {},
        })
        .then((response) => {
          state.commit(
            "setAssetForms",
            response.data.data.attributes.assetforms
          );
          state.commit("setLoading", false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    putFormdata(state, payload) {
      state.commit("setLoading", true);
      let apiurl = process.env.VUE_APP_APIURL;
      // Use this to return a promise
      return new Promise((resolve, reject) => {
        axios
          .put(
            apiurl + "forms/" + state.getters.getFormId + payload.uri,
            payload.data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            // You can access response.data here if needed
            state.commit("setLoading", false);
            resolve(); // Resolve the promise to indicate success
          })
          .catch((err) => {
            console.error(err);
            state.commit("setLoading", false);
            reject(err); // Reject the promise to indicate an error
          });
      });
    },
    createNewSection(state, payload) {
      return new Promise((resolve) => {
        const rkey = Array.from({ length: 6 }, () =>
          String.fromCharCode(65 + Math.floor(Math.random() * 26))
        ).join("");
        let section = { name: payload.name, key: rkey, type: payload.type };
        state.commit("addSection", section);
        state.commit("setActiveSectionKey", rkey);
        resolve();
      });
    },
    setDynamicFormdata(state, payload) {
      state.commit("setDynamicFormdata", payload);
    },
    removeFormdataKey(state, key) {
      state.commit("removeFormdataKey", key);
    },
  },
  modules: {},
});
