import { createRouter, createWebHistory } from "vue-router";

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "home",
    component: lazyLoad("HomeView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: lazyLoad("LoginView"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/forms",
    name: "forms",
    component: lazyLoad("FormsView"),
    meta: {
      auth: true,
    },
  },
  // {
  //   path: "/forms/:formid",
  //   name: "formsections",
  //   component: lazyLoad("FormSectionsView"),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: "/form/:formid",
    name: "formbuilder",
    component: lazyLoad("FormBuilderView"),
    meta: {
      auth: true,
    },
  },
  // {
  //   path: "/forms/:formid/images",
  //   name: "formimages",
  //   component: lazyLoad("FormImagesView"),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: "/forms/:formid/entries",
    name: "formentries",
    component: lazyLoad("FormEntriesView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: lazyLoad("PageNotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log(savedPosition);
    // return { top: 0 };
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
