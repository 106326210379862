<template>
  <NavBar v-if="$route.name !== 'login' && $route.name !== 'formbuilder'" />
  <main>
    <router-view :key="$route.path" />
  </main>
  <footer id="footer" v-if="$route.name !== 'login'">
    <div class="container pt-3">
      <div class="row">
        <div class="col-md-12">
          <span>&copy; </span>
          <a href="https://publey.com" role="button">Publey lead forms</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  components: {
    NavBar,
  },
  mounted() {
    document.title = "Publey";
  },
};
</script>

<style lang="scss">
@import "styles/custom.scss";

@import "bootstrap/scss/bootstrap.scss";
// @import '~bootstrap-vue/src/index.scss';

$height-footer: 50px;

html {
  min-height: 100%;
  position: relative;

  body {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  #app {
    flex: 1; /* Ensure #app takes all available vertical space */
    display: flex;
    flex-direction: column;

    main {
      flex: 1;
      /* Add some padding at the bottom to create space for the footer */
      padding-bottom: $height-footer;
    }
  }

  #footer {
    width: 100%;
    height: $height-footer;
    background-color: #f5f5f5;
    /* Optionally, add a z-index to ensure the footer is below the content */
    z-index: 1;
  }
}
</style>
