<template>
  <nav
    class="navbar navbar-transparent navbar-expand-lg navbar-light bg-light mb-3"
  >
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img
          class="navbar-logo"
          alt="Vue logo"
          src="../assets/logo.png"
          width="100"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li>
            <router-link class="nav-link" :to="{ name: 'forms' }">
              Forms
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav justify-content-end">
          <li>
            <a
              class="nav-link"
              href="https://form.publey.com/ef0f0de8-34ed-4427-8281-3d84f8ec02f1"
              target="_blank"
            >
              <i class="bi bi-bug bi-lg"></i>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-expanded="false"
            >
              <i class="bi bi-person-circle bi-lg"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <!-- <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
              <li><hr class="dropdown-divider" /></li> -->
              <li>
                <a class="dropdown-item" href="#" @click="logout()">Logout</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  props: {},
  created() {},
  methods: {
    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<style scoped>
.bi-lg {
  font-size: 1.5rem; /* Adjust the font size as needed */
}
</style>
