import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Qs from "qs";
import { createAuth } from "@websanova/vue-auth";
import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.esm.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";
import driverOAuth2Google from "@websanova/vue-auth/dist/drivers/oauth2/google.esm.js";
import driverOAuth2Facebook from "@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js";
// import VueGtag from "vue-gtag";

// import "bootstrap/scss/bootstrap.scss";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

// Format nested params correctly
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    // Qs is not included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };
  return config;
});

const app = createApp(App);
app.use(router);

var auth = createAuth({
  plugins: {
    http: axios,
    router: router,
  },
  drivers: {
    http: driverHttpAxios,
    auth: driverAuthBearer,
    router: driverRouterVueRouter,
    oauth2: {
      google: driverOAuth2Google,
      facebook: driverOAuth2Facebook,
    },
  },
  options: {
    rolesKey: "permissions",
    notFoundRedirect: { name: "home" },
    parseUserData: function (response) {
      if (typeof response.data !== "undefined") {
        return response.data.attributes;
      }
    },
    loginData: {
      url: "auth/login",
      method: "POST",
      redirect: "/",
      fetchUser: true,
    },
    fetchData: {
      url: "auth/verify",
      method: "GET",
      enabled: true,
    },
    refreshData: {
      url: "auth/refresh",
      method: "GET",
      enabled: true,
      interval: 30,
    },
  },
});

axios.defaults.baseURL = process.env.VUE_APP_APIURL;
app.config.globalProperties.$axios = axios;

app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(auth)
  // .use(VueGtag, {
  //   config: { id: "G-1BKZ9FT7D2" },
  // })
  .mount("#app");
